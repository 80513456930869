<template>
    <main class="p-5 mt-2">
        <div class="top-header flex justify-between items-center align-items-center">
            <div class="text-xl font-semibold">{{ $t('userManagement') }}</div>
        </div>
        <div class="absolute bg-red-30 inset-0 z-10" @click="modal = false" v-if="modal"></div>

        <div class="mt-3">
            <div class="relative grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="col-span-3 flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden mb-20">
                    <div class="flex justify-between items-center align-items-center w-full bg-light-teal p-2 border-b">
                        <span class="text-xs font-semibold">{{ $t('accountSettings') }}</span>
                        <!-- <span @click="addNewUser" class="bg-theme-blue cursor-pointer text-xs px-3 py-1 rounded-md shadow-md text-white">
                            <span v-if="processing">Adding...</span>
                            <span v-else>Add New User</span>
                        </span> -->
                    </div>
                    <form @submit.prevent="updateUserInfo" class="flex flex-col md:flex-row w-full mb-20">
                        <div class="flex flex-col w-full md:w-1/2 mb-2">
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-xs text-gray-600 w-1/2 mb-2">{{ $t('firstName') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.first_name" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-xs text-gray-600 w-1/2 mb-2">{{ $t('lastName') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.last_name" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-xs text-gray-600 w-1/2 mb-2">{{ $t('email') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="email" v-model="form.email" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div v-if="form.company" class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-xs text-gray-600 w-1/2 mb-2">{{ $t('billingAddress') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.company.street_address" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div v-if="form.company" class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-xs text-gray-600 w-1/2 mb-2">{{ $t('city') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.company.city" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div v-if="form.company" class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-xs text-gray-600 w-1/2 mb-2">{{ $t('country') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <!-- <select v-model="form.company.country" class="w-full bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                        <option :value="item.name" v-for="(item, i) in countries" :key="i">{{ item.name }}</option>
                                    </select> -->
                                    <input type="text" v-model="countryText" @input="searchCountry" @focus="modal = true" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                    <div class="relative">
                                        <div v-if="modal && countryResults.length > 0" class="absolute w-56 bg-white z-50 rounded border shadow-xl top-rem">
                                            <ul>
                                                <li class="py-1 pl-2 hover:bg-gray-100 text-gray-700 cursor-pointer text-sm" v-for="(item, i) in countryResults.slice(0, 5)" :key="i" @click="setCountry(item)">{{ item.name }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="form.company" class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-xs text-gray-600 w-1/2 mb-2">{{ $t('zipCode') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.company.zip_code" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col w-full md:w-1/2">
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-xs text-gray-600 w-1/3 mb-2">{{ $t('username') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.username" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <!-- <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-xs text-gray-600 w-1/3 mb-2">Password <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="password" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div> -->
                            <div v-if="form.company" class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-xs text-gray-600 w-1/3 mb-2">{{ $t('companyName') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.company.company_name" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div v-if="form.company" class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-xs text-gray-600 w-1/3 mb-2">{{ $t('companyNumber') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.company.company_number" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div v-if="form.company" class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-xs text-gray-600 w-1/3 mb-2">{{ $t('companyVatId') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.company.company_vat_id" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div v-if="form.company" class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-xs text-gray-600 w-1/3 mb-2">{{ $t('companyEmail') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="email" v-model="form.company.email" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div v-if="form.company" class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-xs text-gray-600 w-1/3 mb-2">{{ $t('phoneNumber') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.company.phone_number" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-xs text-gray-600 w-1/3 mb-2"></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0 mb-3">
                                    <button type="submit" :disabled="processing" class="focus:outline-none bg-theme-blue text-sm px-3 py-1 rounded-md shadow-md text-white">
                                        <span v-if="processing">{{ $t('updating') }}</span>
                                        <span v-else>{{ $t('update') }}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapGetters } from 'vuex'
import Bus from '../../bus'
// import { getCountries } from './helper/countries'
const country = () => import('./helper/countries')

    export default {
        name : 'Accounts',
        data () {
            return {
                countries : [],
                loading : true,
                processing : false,
                countryText : '',
                modal : false,
                countryResults : [],
                form : {
                    first_name : '',
                    last_name : '',
                    email : '',
                    role : '',
                    company : {
                        street_address  : '',
                        city  : '',
                        country  : '',
                        zip_code  : '',
                        company_name  : '',
                        company_number  : '',
                        company_vat_id  : '',
                        email  : '',
                        phone_number  : '',
                    }
                }
            }
        },
        computed : {
            ...mapGetters({
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_USER_REF : 'auth/GET_USER_REF',
                GET_LOGIN_USER : 'jitcontrol/GET_LOGIN_USER',
                GET_USER_INFORMATION : 'jitcontrol/GET_USER_INFORMATION',
                GET_CHOOSEN_COMPANY : 'jitcontrol/GET_CHOOSEN_COMPANY'
            })
        },
        mounted () {
            country().then(res => {
                const { getCountries } = res
                this.countries = getCountries()
            })
            this.getUser()
            this.getUserInformation()
        },
        methods : {
            getUser () {
                if (this.GET_USER_REF) {
                    this.loading = true
                    this.$store.dispatch('jitcontrol/getLoginUserInfo', this.GET_USER_REF)
                    .then(_ => { 
                        this.loading = false
                        this.form = this.GET_LOGIN_USER
                    })
                    .catch(err => {
                        if (err.response.status === 401) {
                            this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                            .then(_ =>{
                                this.getUser()
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                Bus.emit('sign-out')
                            })
                        }
                    })
                }
            },
            getUserInformation () {
                this.loading = true
                this.$store.dispatch('jitcontrol/getUserInfo')
                .then(_ => { 
                    this.loading = false
                    if (this.GET_CHOOSEN_COMPANY) {
                        const filter = this.GET_USER_INFORMATION.filter(el => el.company.uuid === this.GET_CHOOSEN_COMPANY)
                        this.form.company = filter[0].company
                    }
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                        .then(_ =>{
                            this.getUserInformation()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            Bus.emit('sign-out')
                        })
                    }
                })
            },
            updateUserInfo () {
                if (this.form.firstname === '' || this.form.lastname === '' || this.form.company_number === '' || this.form.company.email === '') {
                    this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
                } else if (!this.$services.helpers.validateEmail(this.form.email) || !this.$services.helpers.validateEmail(this.form.company.email)) {
                    this.$services.helpers.notification(this.$t('invalidEmail'), 'error', this)
                } else if (this.$services.helpers.validateCompanyNumber(this.form.company.company_number)) {
                    this.$services.helpers.notification(this.$t('invalidOrganizationNumber'), 'error', this)
                } else {
                    this.processing = true
                    const payload = {
                        first_name : this.form.first_name,
                        last_name : this.form.last_name,
                        userID : this.GET_LOGIN_USER.uuid
                    }

                    if (this.GET_USER_INFORMATION[0].role !== 'Employee') {
                        payload.companyID = this.GET_CHOOSEN_COMPANY

                        payload['company'] = {
                                city : this.form.company.city,
                                company_name : this.form.company.company_name,
                                company_number : this.form.company.company_number,
                                phone_number  : this.form.company.phone_number,
                                company_vat_id : this.form.company.company_vat_id,
                                street_address : this.form.company.street_address,
                                zip_code : this.form.company.zip_code,
                                country : this.form.company.country,
                        }

                        // if (this.GET_USER_INFORMATION[0].email !== this.form.email) {
                        //     payload.email = this.form.email
                        // }

                        if (this.GET_USER_INFORMATION[0].company.email !== this.form.company.email) {
                            payload.company.email = this.form.company.email
                        }
                    }


                    this.$store.dispatch('jitcontrol/updateUserInfo', payload)
                    .then(_ => {
                        this.processing = false
                        this.$services.helpers.notification(this.$t('userUpdated'), 'success', this)
                    })
                    .catch(error => {
                        this.processing = false
                        if (error.response.status === 400) {
                            this.$services.helpers.notification(error.response.data, 'error', this)
                        }
                        if (error.response.status === 401) {
                            this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                            .then(_ =>{
                                this.updateUserInfo()
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                Bus.emit('sign-out')
                            })
                        }
                    })
               }
            },
            setCountry (item) {
                this.countryText = item.name
                this.form.company.country = item.key
                this.modal = false
            },
            searchCountry () {
                this.countryResults = this.countries.filter(country => {
                    return String(country.key.toLowerCase()).includes(this.countryText.toLowerCase())
                })
            },
        }

    }
</script>

<style lang="scss" scoped>

</style>